import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, catchError, from, map, throwError } from 'rxjs';
import { LocalStorageService } from '../localStorage/local-storage.service';
import { Store } from '@ngrx/store';
import { ToasterActions } from '../../store/toaster/actions/toaster.actions';
@Injectable({
  providedIn: 'root',
})
export class HelpersService {
  currensies: Array<any> = [
    {
      name: 'EUR',
      countries: [
        'aut',
        'dnk',
        'bgr',
        'lva',
        'hrv',
        'cyp',
        'cze',
        'est',
        'lbn',
        'lux',
        'mco',
        'pol',
        'svk',
        'mlt',
        'fin',
        'deu',
        'grc',
        'fin',
        'hun',
        'irl',
        'ita',
        'nld',
        'prt',
        'rou',
        'svn',
        'esp',
        'che',
        'grl',
        'swe',
        'isl',
        'bel',
        'ukr',
        'swe',
        'bih',
        'mne',
        'ltu',
        'mda',
        'srb',
        'alb',
        'mkd',
        'nor',
      ],
      replace: 'eun',
      translation: 'currencies.eur',
    },
    {
      name: 'USD',
      replace: 'usa',
      translation: 'currencies.usd',
    },
    {
      name: 'AUD',
      countries: ['aus'],
      replace: 'aus',
      translation: 'currencies.aud',
    },
    {
      name: 'CAD',
      countries: ['can'],
      replace: 'can',
      translation: 'currencies.cad',
    },
    {
      name: 'BRL',
      countries: ['bra'],
      replace: 'bra',
      translation: 'currencies.brl',
    },
    {
      name: 'MXN',
      countries: ['mex'],
      replace: 'mex',
      translation: 'currencies.mxn',
    },
    { name: 'RUB', countries: ['rus'], replace: 'rus', translation: 'currencies.rub' },
  ];
  promotionKey: {
    key: string;
    value: string;
  }[] = [
    {
      key: 'description',
      value: 'description_l_',
    },
    {
      key: 'code',
      value: 'code_l_',
    },
    {
      key: 'opengraphTitle',
      value: 'opengraphTitle_l_',
    },
    {
      key: 'opengraphDescription',
      value: 'opengraphDescription_l_',
    },
    {
      key: 'title',
      value: 'title_l_',
    },
    {
      key: 'name',
      value: 'name_l_',
    },
    {
      key: 'short',
      value: 'short_l_',
    },
    {
      key: 'content',
      value: 'content_l_',
    },
  ];
  categories: any = {
    // animals: 'https://cdn.playasballs.com//wazbee/icons/categories/animals.svg',
    antiquity: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/antiquity.svg',
    asia: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/asia.svg',
    baccaratglobal: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/baccarat.svg',
    blackjacks: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/blackjack.svg',
    books: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/books.svg',
    // brandedgames: 'https://cdn.playasballs.com//wazbee/icons/categories/branding.svg',
    buyfeature: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/feature_buy.svg',
    catchWave: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/catch_the_wave.svg',
    christmas: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/christmas.svg',
    dropandwins: 'https://cdn.chipychapa.com/welle/General_site/Categories/categories/drop_n_wins.svg',
    egypt: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/egypt.svg',
    // fishing: 'https://cdn.playasballs.com//wazbee/icons/categories/fishing.svg',
    halloween: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/halloween.svg',
    hot: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/hot_games.svg',
    jackpots: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/jackpots.svg',
    livecasino: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/live_slots.svg',
    megaway: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/megawaves.svg',
    // megaway: 'https://cdn.playasballs.com//wazbee/icons/categories/megaways.svg',
    new: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/new.svg',
    other: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/other.svg',
    ourpick: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/welles_choice.svg',
    pokerglobal: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/poker.svg',
    popular: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/popular.svg',
    rouletteglobal: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/roulette.svg',
    scratchcards: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/scratch_cards.svg',
    seaAdventures: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/sea_adventures.svg',
    sweets: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/sweets.svg',
    slots: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/slots.svg',
    stpatrick: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/clover.svg',
    tablegames: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/table_games.svg',
    videopoker: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/video_poker.svg',
    vikings: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/vikings.svg',
    // virtualsports: 'https://cdn.playasballs.com//wazbee/icons/categories/poker.svg',
    clover: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/clover.svg',
    catchthewave: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/catch_the_wave.svg',
    crashgame: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/crush.svg',
    mines: 'https://cdn.chipychapa.com//welle/General_site/Categories/categories/mines.svg',
  };

  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private http: HttpClient,
    private storage: LocalStorageService,
    private store: Store
  ) {}

  getBrowserlanguages() {
    return [
      ...new Set(
        navigator.languages.map((lang: any) => {
          return lang?.split('-')[0].toLowerCase();
        })
      ),
    ];
  }
  blockDoubleTapIPhone() {
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      let lastTouchEnd = 0;
      document.addEventListener(
        'touchend',
        (event) => {
          const now = new Date().getTime();
          if (now - lastTouchEnd <= 300) {
            event.preventDefault();
            (event.target as HTMLButtonElement).click();
          }
          lastTouchEnd = now;
        },
        true
      );
    }
  }
  public mapKeystoneFieldsTransform(obj: any) {
    const result: any = {
      ...obj,
    };
    for (const key in result) {
      if (key.includes('description')) {
        result.description = this.parseObj(result[key]);
      }
      if (key.includes('short')) {
        result.short = this.parseObj(result[key]);
      }
      if (key.includes('opengraphTitle')) {
        result.opengraphTitle = this.parseObj(result[key]);
      }
      if (key.includes('opengraphDescription')) {
        result.opengraphDescription = this.parseObj(result[key]);
      }
      if (key.includes('title')) {
        result.title = this.parseObj(result[key]);
      }
      if (key.includes('name')) {
        result.name = this.parseObj(result[key]);
      }
    }
    return result;
  }
  public parseObj(text: string) {
    const currency = this.storage.getSessionStorage('currency');
    const resultList: any = text?.match(/{([\s\S]+?)}/g);
    let result: string = text;
    if (resultList?.length > 0) {
      for (let i = 0; i < resultList.length; i++) {
        if (this.isJson(resultList[i])) {
          result = result.replace(resultList[i], JSON.parse(resultList[i])[currency ? currency : 'undefined']);
        }
      }
    }
    return result;
  }

  modalControl(event: string) {
    this.router.navigate([], {
      relativeTo: this.activateRoute,
      queryParams: {
        modal: event,
      },
      replaceUrl: true,
    });
  }

  getUserIp() {
    return this.http.get<any>('https://api.bigdatacloud.net/data/client-ip');
  }

  getCountries() {
    const lang = 'en';
    return this.http.get(`/countries?lang=${lang}`).pipe(map((res: any) => res?.countries));
  }

  disablePseudoElementsIPhone(): void {
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent) && window.innerWidth < 768) {
      const style = document.createElement('style');
      style.innerHTML = `
    * {
     &:hover,
     &::after,
     &::before {
     content: none !important;
     }
    }
  `;
      document.head.appendChild(style);
    }
  }

  public detectBrowser() {
    if ((navigator.userAgent.indexOf('Opera') || navigator.userAgent.indexOf('OPR')) != -1) {
      return 'Opera';
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
      return 'Chrome';
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
      return 'Safari';
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
      return 'Firefox';
    } else {
      return 'Unknown';
    }
  }
  getCookie(name: any) {
    const matches = document.cookie.match(
      // eslint-disable-next-line no-useless-escape
      new RegExp('(?:^|; )' + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + '=([^;]*)')
    );
    return matches ? decodeURIComponent(matches[1]) : '';
  }
  public changeGamesUrl(game: Array<any>) {
    game.forEach((element: any) => {
      const image = element.image;
      if (element.image.includes('gstatic'))
        element.image = element.image.replace(`${image}`, `https://agstatic.com${image}`);
      element.image = element.image.replace(`/gstatic`, ``);
    });
    return game;
  }

  mapKeystoneFields(obj: any) {
    // remove "_l_[lang]" from object keys
    const result: any = {
      ...obj,
    };
    for (const key in result) {
      this.promotionKey.find((el: any) => {
        if (key.includes(el.value)) {
          result[el.key] = result[key];
          delete result[key];
        }
      });
    }
    return result;
  }
  getBirthdayDateSumsub(day: any, month: any, year: any) {
    return year + this.dateInFomat(month) + this.dateInFomat(day);
  }

  dateInFomat(date: any) {
    if (date < 10) {
      date = '0' + date;
    }
    return date;
  }
  getPaymentSystems() {
    return this.http.get<any[]>(`/paymentSystems`).pipe(
      map((data: any) => {
        const result: any = [];
        data.forEach((obj: any) => {
          if (!(obj?.alias.indexOf('_helper') >= 0)) {
            result.push({
              ...obj,
              select: false,
            });
          }
        });
        return result;
      })
    );
  }
  getVerificationStatus(uniqueID: string, userID: string) {
    return this.http.get(`https://helper.coduluz.com/user-sumsub/?userId=${userID}&uniqueId=${uniqueID}`);
  }
  setUserIds(userProfile: any, birthDate?: any) {
    if (birthDate && Object.values(birthDate).length > 0) {
      return (
        userProfile.firstName +
        userProfile.lastName +
        this.getBirthdayDate(birthDate.day, birthDate.month, birthDate.year)
      );
    }

    return (
      userProfile.firstName +
      userProfile.lastName +
      this.getBirthdayDate(userProfile.birthDay, userProfile.birthMonth, userProfile.birthYear)
    );
  }
  getBirthdayDate(day: any, month: any, year: any) {
    return this.dateInFomat(day) + this.dateInFomat(month) + year;
  }
  jsonConvert(str: string) {
    try {
      return JSON.parse(str);
    } catch (e) {
      return;
    }
  }
  isJson(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  handleCategories(categories: Array<any>) {
    return categories.map((elem: any) => {
      const key = Object.keys(this.categories).find((element: string) => {
        return element == elem.slug;
      });
      if (key != undefined) elem.img = this.categories[key];
      return elem;
    });
  }
  checkIfSportBook(gameToLaunch: any, isAuth: boolean) {
    if (gameToLaunch?.id == '1477472' && gameToLaunch?.demo == '1') {
      this.store.dispatch(
        ToasterActions['[toaster]SetToasterMessgageSuccess']({
          data: {
            type: 'info',
            place: '',
            message: isAuth
              ? 'notifications.toaster.sportbook_demo.is_auth'
              : 'notifications.toaster.sportbook_demo.not_auth',
            isLoaded: true,
            url: isAuth ? '' : '/auth/sign-in',
            img: 'fishConfident',
          },
        })
      );
    }
  }

  transfer() {
    return this.http.get<any>('/transfer');
  }

  strDateToTimestamp(date: string) {
    return Date.parse(date);
  }

  generateRandomPostalCode() {
    const length: number = Math.floor(Math.random() * 2) + 1;
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return 'srwelle' + result[length - 1];
  }

  generateRandomString() {
    const length = Math.floor(Math.random() * 6) + 2;
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return 'srwelle' + result;
  }

  checkToScipPEP(countryCode: string) {
    let result: boolean = false;
    const countrySkipPep = ['rus'];
    result = countrySkipPep.includes(countryCode);

    return result;
  }
  generateFingerPrint(): Observable<string> {
    try {
      const fingerprintData = {
        userAgent: navigator.userAgent || '',
        screenSize: `${screen.width || ''}x${screen.height || ''}`,
        colorDepth: screen.colorDepth || '',
        cookies: navigator.cookieEnabled || '',
        touchPoints: navigator.maxTouchPoints || '',
        pdf: navigator.pdfViewerEnabled || '',
        memory: navigator.hardwareConcurrency || '',
        language: navigator.language || '',
        timezone: new Date().getTimezoneOffset(),
      };
      const fingerprintString = JSON.stringify(fingerprintData);
      return from(crypto.subtle.digest('SHA-256', new TextEncoder().encode(fingerprintString))).pipe(
        map((hashBuffer) => {
          const hashArray = Array.from(new Uint8Array(hashBuffer));
          const hashHex = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
          return hashHex;
        }),
        catchError((error) => {
          console.error('Error generating fingerprint:', error);
          return throwError(() => error);
        })
      );
    } catch (error) {
      console.error('Error generating fingerprint:', error);
      return throwError(() => error);
    }
  }
  setCountryCurrency(currentCountry: string) {
    const allCurrencies = this.currensies;
    for (const currency of allCurrencies) {
      if (currency.countries?.includes(currentCountry)) {
        return currency.name;
      }
    }
    return 'USD';
  }
}
